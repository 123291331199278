import React from "react";

import Header from "../../components/header";

import Modal from "@mui/material/Modal";

import BoxModal from "../../components/boxModal";

import { useSales } from "./hooks/useSales";
import { TableBase } from "../../components/tablesBase/TableBase";
import { ListReports } from "./components/ListReports";
import { Stack } from "@mui/material";
import { MdOutlinePointOfSale } from "react-icons/md";

export default function Sales() {
  const {
    dataSale,
    isLoading,
    columnsTable,
    dataReport,
    renderOtherActions,
    listModais,
  } = useSales();

  const PAGETITLE = "Vendas";

  return (
    <Stack gap={2}>
      <Header
        title={PAGETITLE}
        titleButton={`Adicionar ${PAGETITLE.toUpperCase()}`}
        icon={<MdOutlinePointOfSale />}
      />
      <ListReports dataReport={dataReport} />

      <TableBase
        title="Lista de Compras"
        data={dataSale || []}
        columns={columnsTable}
        isLoading={isLoading}
        otherActions={renderOtherActions}
      />

      {listModais &&
        listModais?.map((modal, index) => (
          <Modal key={`modal-${index}`} open={modal.open}>
            <BoxModal handleClose={modal.onClose}>{modal.Component}</BoxModal>
          </Modal>
        ))}
    </Stack>
  );
}
