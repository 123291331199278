import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "../templates/styleGlobal";
import Routes from "../routes";

import { Context } from "../context";
import { MenuProvider } from "../context/MenuContext";

function App() {
  return (
    <BrowserRouter>
      <MenuProvider>
        <GlobalStyle />
        <Context>
          <Routes />
        </Context>
      </MenuProvider>
    </BrowserRouter>
  );
}

export default App;
