import { Paper, Stack, Typography, useTheme } from "@mui/material";
import { ChartsBar } from "../../../../components/charts/chartsBar/chartsBar";

export function ListClientsForChurchs({ dataChurch }) {
  const theme = useTheme();
  return (
    <Stack>
      <Paper elevation={2}>
        <Stack gap={5} p={2}>
          <Typography variant="h6">
            Participantes presentes por congregação
          </Typography>
          <ChartsBar
            data={dataChurch}
            dataKeyX={{ key: "name_church", name: "Congregação" }}
            dataKeyY=""
            dataKeyBar={[
              {
                key: "presence_count",
                color: theme.palette.primary.main,
                label: "Total presentes",
              },
            ]}
          />
        </Stack>
      </Paper>
    </Stack>
  );
}
