import {
  Paper,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  colors,
} from "@mui/material";

import { DeleteOutline, EditOutlined, Visibility } from "@mui/icons-material";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { ButtonsActions } from "./components/ButtonsActions";
import { BiEdit } from "react-icons/bi";
import { GrView } from "react-icons/gr";

export function TableBase({
  title,
  columns,
  onDelete,
  onEdit,
  otherActions,
  isLoading,
  isError,
  onView,

  ...props
}) {
  const table = useMaterialReactTable({
    ...props,
    columns,
    enableRowActions:
      onDelete || onEdit || onView || otherActions ? true : false,
    positionActionsColumn: "last",
    enableColumnFilters: true,
    enableColumnFilterModes: true,
    enableColumnOrdering: false,
    enableFullScreenToggle: true,
    state: {
      isLoading,
    },
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: false,
    },
    renderRowActions: ({ row }) => {
      return (
        <>
          <Stack direction="row" gap={1.5} justifyContent="flex-end">
            {otherActions && otherActions(row)}
            {onView && (
              <ButtonsActions
                title={"Visualizar"}
                onClick={() => onView && onView(row)}
                icon={<GrView fontSize="inherit" />}
              />
            )}
            {onEdit && (
              <ButtonsActions
                title={"Editar"}
                onClick={() => onEdit && onEdit(row)}
                icon={<BiEdit fontSize="inherit" />}
              />
            )}
            {onDelete && (
              <ButtonsActions
                title={"Remover"}
                onClick={() => onDelete && onDelete(row)}
                icon={<DeleteOutline fontSize="inherit" color="error" />}
              />
            )}
          </Stack>
        </>
      );
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    muiTableBodyProps: {
      sx: {
        "& tr > td": {
          backgroundColor: "#fff",
        },
      },
    },
    muiTableHeadProps: {
      sx: {
        pt: 1,
        "& tr > th": {
          backgroundColor: colors.grey[100],
          py: 2,
        },
      },
    },
    muiTopToolbarProps: {
      sx: {
        marginBottom: 2,
      },
    },
    paginationDisplayMode: "pages",
    localization: MRT_Localization_PT_BR,
  });

  return (
    <>
      {props.data && (
        <Paper sx={{ p: 1 }}>
          <Stack gap={2} pb={3}>
            <Typography variant="h6" ml={2} mt={2}>
              {title}
            </Typography>

            <MaterialReactTable table={table} />
          </Stack>
        </Paper>
      )}
    </>
  );
}
