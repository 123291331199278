import { IconButton, Tooltip } from "@mui/material";

export function ButtonsActions({ title, onClick, icon, ...props }) {
  return (
    <Tooltip title={title} placement="bottom-start">
      <IconButton
        size="small"
        onClick={onClick}
        sx={{ fontSize: 24 }}
        {...props}
      >
        {icon}
      </IconButton>
    </Tooltip>
  );
}
