import React from "react";
import Header from "../../components/header";

import { Button, Stack } from "@mui/material";

import ViewClient from "./view";

import { PrintQrcode } from "./modals/printQrcode";

import { ModalDefault } from "../../components/Modal";
import { RegisterClient } from "./modals/registerClient";
import { GoPeople } from "react-icons/go";
import { RiUserAddLine } from "react-icons/ri";
import { useClients } from "./hooks/useClients";
import { TableBase } from "../../components/tablesBase/TableBase";
import { MdOutlineQrCodeScanner } from "react-icons/md";

export default function Clients() {
  const {
    clients,
    isLoading,
    columns,
    handleSetListIdClients,
    handleOpenModal,
    openModalQrCode,
    listIdClients,
    renderOtherActions,
    updateClient,
    handleCloseModalUpdateClient,
    openModalUpdateAndCreateClient,
    handleOpenModalCreateClient,

    openModalView,
    handleCloseModalView,
  } = useClients();

  const listModals = [
    {
      open: openModalQrCode,
      onclose: handleOpenModal,
      infoheader: {
        title: "Impressão de QrCodes",
        subtitle: `Para Imprimir as etiquetas com qrcode 
                primeiramente selecione a possição inicial.`,
      },
      content: (
        <PrintQrcode clients={listIdClients} onclose={handleOpenModal} />
      ),
    },
    {
      open: openModalUpdateAndCreateClient,
      onclose: handleCloseModalUpdateClient,
      infoheader: {
        title: updateClient ? "Editar Participante" : "Novo Participante",
        subtitle: "",
      },
      content: (
        <RegisterClient
          onClose={handleCloseModalUpdateClient}
          dataUpdate={updateClient}
        />
      ),
    },
    {
      open: openModalView,
      onclose: handleCloseModalView,
      infoheader: {
        title: "Visualizar Participante",
        subtitle: "",
      },
      content: (
        <ViewClient dataClient={updateClient} onclose={handleCloseModalView} />
      ),
    },
  ];

  return (
    <Stack gap={2}>
      <Header
        title="Participantes"
        titleButton="Novo participantes"
        handleShowModal={handleOpenModalCreateClient}
        IconButton={<RiUserAddLine />}
        icon={<GoPeople />}
      />

      <TableBase
        title="Lista de Participantes"
        data={clients || []}
        columns={columns}
        isLoading={isLoading}
        renderTopToolbarCustomActions={({ table }) => {
          const selectedRows = table.getSelectedRowModel().rows;
          return selectedRows.length ? (
            <Button
              onClick={() => {
                const ids = selectedRows.map((row) => {
                  return {
                    id: row.original.id,
                    name: row.original.name_client,
                  };
                });
                handleSetListIdClients(ids);
              }}
              disabled={table?.selectedRows?.length === 0}
              variant="contained"
              startIcon={<MdOutlineQrCodeScanner />}
            >
              Imprimir QR CODE
            </Button>
          ) : null;
        }}
        enableRowSelection={(row) => row.original.paid_sale === "yes"}
        otherActions={renderOtherActions}
      />

      {listModals.map((modal) => (
        <ModalDefault
          key={modal.infoheader.title}
          open={modal.open}
          onClose={modal.onclose}
          infoheader={modal.infoheader}
        >
          {modal.content}
        </ModalDefault>
      ))}
    </Stack>
  );
}
