import React from "react";
import {
  Stack,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  Box,
  Button,
} from "@mui/material";

import { CreditScore } from "@mui/icons-material";
import { useFormik } from "formik";
import { listMenuTypeOfSales } from "../../constants/App";
import { LoadingButton } from "@mui/lab";

const FormDownPay = ({ onClose, data, handleSubmit, isLoading }) => {
  const { id, client, data_sale, value_sale } = data || {};

  const formik = useFormik({
    initialValues: {
      form_of_payment: "",
    },
    onSubmit: async (values) => {
      if (window.confirm("Deseja Confirmar a baixa desta compra?")) {
        const payload = {
          paid_sale: "yes",
          form_of_payment: values.form_of_payment,
          status: "approved",
          id,
        };

        handleSubmit(payload);
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} gap={2}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CreditScore sx={{ marginRight: "5px" }} />
          <Typography variant="h6"> Baixar venda</Typography>
        </Box>

        <Stack gap={2}>
          <Typography variant="h6">Descrição da venda</Typography>

          <Stack>
            <Typography variant="body1">
              <strong>Participante:</strong> {client}
            </Typography>
            <Typography variant="body1">
              <strong>Data da venda:</strong> {data_sale}
            </Typography>
            <Typography variant="body1">
              <strong>Valor:</strong> {value_sale}
            </Typography>
          </Stack>
        </Stack>

        <FormControl fullWidth size="medium">
          <InputLabel id="label-form-of-payment">
            {" "}
            Forma de pagamento
          </InputLabel>
          <Select
            labelId="label-form-of-payment"
            label="Forma de pagamento"
            name="form_of_payment"
            value={formik.values.form_of_payment}
            required={true}
            onChange={formik.handleChange}
          >
            {listMenuTypeOfSales.map((item, index) => (
              <MenuItem value={item.value}>{item.labels}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Stack spacing={2} direction="row-reverse">
          <Button
            variant="outlined"
            color="error"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <LoadingButton variant="outlined" type="submit" loading={isLoading}>
            Baixar
          </LoadingButton>
        </Stack>
      </Stack>
    </form>
  );
};

export default FormDownPay;
