import { Modal, Paper, Stack } from "@mui/material";

export function BaseModal({ children, open, onClose }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Stack
        alignItems="center"
        justifyContent={"center"}
        height={"100%"}
        width={"100%"}
        sx={{
          overflowY: "auto",
        }}
      >
        <Paper sx={{ width: ["100%", "100%", "60%", "50%"] }}>{children}</Paper>
      </Stack>
    </Modal>
  );
}
