import React from "react";

import Button from "@mui/material/Button";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function Header({
  title,
  titleButton = "",
  handleShowModal,
  IconButton,
  colorButton,
  icon,
}) {
  const theme = useTheme();
  return (
    <Stack mb={5}>
      <Stack direction="row" justifyContent="space-between" pb={4} pt={2} gap>
        <Stack
          direction="row"
          gap={1}
          fontSize={28}
          sx={{ color: theme.palette.primary.main }}
        >
          {icon && icon}
          <Typography variant="h5">{title}</Typography>
        </Stack>

        {titleButton && (
          <Button
            onClick={handleShowModal}
            startIcon={IconButton || <Add />}
            color={colorButton ?? "primary"}
            variant="contained"
            size="large"
          >
            {titleButton}
          </Button>
        )}
      </Stack>
      <Divider orientation="horizontal" flexItem />
    </Stack>
  );
}
