import {
  Stack,
  FormControlLabel,
  Checkbox,
  TextField,
  MenuItem,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";
import { RegisterContainer } from "./style";
import { useFormik } from "formik";
import { registerClient } from "../../../../helpes/validations";
import { useEffect, useMemo, useState } from "react";
import UseApi from "../../../../services/api";
import { StateContext } from "../../../../context";
import { useContext } from "react";
import { useApiClients } from "../../../../services/hooks/useApiClients";

export function RegisterClient({ dataUpdate, onClose }) {
  const api = UseApi();
  const { actions } = useContext(StateContext);

  const [churchs, setChurchs] = useState([]);
  const [loadingChurchs, setLoadingChurchs] = useState(false);

  function handleCloseModal() {
    onClose(false);
  }

  const {
    createClient,
    isLoadingCreateClient,
    updateClient,
    isLoadingUpdateClient,
  } = useApiClients({ onCloseModal: handleCloseModal });

  const loading = useMemo(
    () => isLoadingCreateClient || isLoadingUpdateClient,
    [isLoadingCreateClient, isLoadingUpdateClient]
  );

  const valueFormikDefault = {
    id: dataUpdate?.id || "",
    name_client: dataUpdate?.name_client || "",
    id_church: dataUpdate?.id_church || "",
    email: dataUpdate?.email || "",
    phone: dataUpdate?.phone || "",
    shepherd: !!dataUpdate?.shepherd || false,
    paying_sale: !!dataUpdate?.paying_sale || false,
  };

  const formik = useFormik({
    initialValues: valueFormikDefault,
    onSubmit: async (values) => {
      const payload = {
        ...values,
        shepherd: values.shepherd ? 1 : 0,
        paying_sale: values.paying_sale ? "no" : "yes",
      };

      return !dataUpdate
        ? createClient(payload)
        : updateClient({ ...payload, id: dataUpdate.id });
    },
    validationSchema: registerClient,
  });

  const getChurch = async () => {
    setLoadingChurchs(true);
    const response = await api.get("church", actions);
    if (Object.keys(response).length === 0) {
      setLoadingChurchs(false);
      return "";
    }
    setChurchs(response.data);
    setLoadingChurchs(false);
  };

  useEffect(() => {
    getChurch();
    formik.resetForm();
  }, []);

  return (
    <RegisterContainer>
      <input type="hidden" name="id" value={formik.values.id} />
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Checkbox
                  name="shepherd"
                  checked={formik.values.shepherd}
                  onChange={formik.handleChange}
                  disabled={loading}
                />
              }
              label="Participante é um pastor?"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="paying_sale"
                  checked={formik.values.paying_sale}
                  onChange={formik.handleChange}
                  disabled={loading}
                />
              }
              label="Participante isendo ?"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
              disabled={dataUpdate}
            />
          </Stack>
          <TextField
            name="name_client"
            type="text"
            label="Nome"
            fullWidth
            size="normal"
            className="inputUser"
            value={formik.values.name_client}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            helperText={
              formik.touched.name_client && formik.errors.name_client
                ? formik.errors.name_client
                : ""
            }
            error={formik.touched.name_client && formik.errors.name_client}
            disabled={loading}
          />

          <TextField
            name="email"
            type="email"
            label="Email"
            fullWidth
            size="normal"
            className="inputUser"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.email && formik.errors.email}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
            disabled={loading}
          />

          <TextField
            name="phone"
            type="text"
            label="Telefone"
            fullWidth
            size="normal"
            className="inputUser"
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={formik.touched.phone && formik.errors.phone}
            helperText={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : ""
            }
            disabled={loading}
          />

          <TextField
            labelId="label-church"
            label="Igreja"
            name="id_church"
            value={formik.values.id_church}
            onChange={formik.handleChange}
            error={formik.touched.id_church && formik.errors.id_church}
            helperText={
              formik.touched.id_church && formik.errors.id_church
                ? formik.errors.id_church
                : ""
            }
            disabled={loadingChurchs || loading}
            select
          >
            <MenuItem value=""></MenuItem>
            {churchs &&
              churchs.map((church) => (
                <MenuItem value={church.id} key={church.id}>
                  {church.name_church}
                </MenuItem>
              ))}
          </TextField>

          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={3}
            sx={{ paddingTop: "2rem" }}
          >
            <LoadingButton
              type="submit"
              loading={isLoadingCreateClient || isLoadingUpdateClient}
              variant="outlined"
            >
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </RegisterContainer>
  );
}
