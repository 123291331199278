import { useFormik } from "formik";
import { useApiChruchs } from "../../../services/hooks/useApiChurchs";
import * as Yup from "yup";
import { useState } from "react";

export function useChurchs() {
  const [isOpenModalNewChurch, setIsOpenModalNewChurch] = useState(false);

  const { churchs, isLoading, deleteChurch, sendNewChurch, updateChurch } =
    useApiChruchs({
      closeModal: handleCloseModal,
    });

  const formik = useFormik({
    initialValues: {
      id: "",
      name_church: "",
      responsible_church: "",
    },
    validationSchema: Yup.object({
      name_church: Yup.string()
        .required("Informe nome da igreja")
        .min(3)
        .max(256),
      responsible_church: Yup.string().required(
        "Informe o responsavel pela igreja"
      ),
    }),
    onSubmit: async (value) => {
      if (value.id) {
        updateChurch(value);
      } else {
        sendNewChurch(value);
      }
    },
  });

  function handleOpenModalNewChurch() {
    setIsOpenModalNewChurch(!isOpenModalNewChurch);
  }

  function handleUpdateChurch(data) {
    const { id, name_church, responsible_church } = data.original || {};

    formik.setValues({
      id,
      name_church,
      responsible_church,
    });

    setIsOpenModalNewChurch(true);
  }

  function handleCloseModal() {
    setIsOpenModalNewChurch(false);
    formik.resetForm();
  }

  function handleDeleteChurch(data) {
    const { id } = data.original || {};

    if (window.confirm("Deseja realmente remover este item?")) {
      deleteChurch(id);
    }
  }

  return {
    churchs,
    isLoading,
    formik,
    isOpenModalNewChurch,
    handleOpenModalNewChurch,
    handleUpdateChurch,
    handleCloseModal,
    handleDeleteChurch,
  };
}
