export const typeOfSales = {
  ticket: "Boleto",
  check: "Cheque",
  credit_card: "Cartão de Crédito",
  debit_card: "Cartão de Débito",
  deposit: "Depósito",
  dinheiro: "Dinheiro",
  pix: "Pix",
  account_money: "Transferência Bancária",
  other: "Outro",
};

export const listMenuTypeOfSales = Object.entries(typeOfSales).map(
  ([key, value]) => ({
    value: key,
    labels: value,
  })
);
