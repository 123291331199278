import { useMutation } from "react-query";

import { useContext } from "react";
import { StateContext } from "../../context";
import { errorNotification } from "../../helpes/notification";
import { SESSION_STORE_CONSTANTS } from "../../constants/sessionStore";
import { useHistory } from "react-router-dom";
import { axiosClient } from "../axiosClient";

export function useApiAuth() {
  const { actions } = useContext(StateContext);
  const history = useHistory();

  const { mutate: sendAuth, isLoading } = useMutation(
    "authentication",
    async (payload) => {
      return axiosClient
        .post(`api/users/login`, payload)
        .then((response) => response.data);
    },
    {
      onSuccess: (data) => {
        sessionStorage.setItem(SESSION_STORE_CONSTANTS.TOKEN, data.token);
        sessionStorage.setItem(
          "user",
          JSON.stringify({
            id: data.id,
            name: data.name,
            email: data.email,
          })
        );
        actions.setUser({
          name: data.name,
          email: data.email,
          token: data.token,
        });

        history.push("/dashboard");
      },
      onError: () => {
        errorNotification(actions, "Usuario ou senha incorreto");
      },
    }
  );

  return { sendAuth, isLoading };
}
