import { useFormik } from "formik";
import * as Yup from "yup";
import { useApiAuth } from "../../../services/hooks/useApiAuth";

const schema = Yup.object({
  email: Yup.string().email("Email invalido!").required("Informe um email"),
  password: Yup.string()
    .min(3, "Informe o valor valido")
    .required("Informe uma senha"),
});

export function useAuth() {
  const { sendAuth, isLoading } = useApiAuth();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async (value) => {
      sendAuth(value);
    },
  });

  return { formik, isLoading };
}
