import { useContextSelector } from "use-context-selector";
import { MenuContext } from "../../../../../context/MenuContext";
import { useMemo } from "react";
import { itensMenu } from "../../../../../routes/listRoutes";

export function useMenuDrawer() {
  const {
    sizeMenu,
    handleIsIcon,
    showMenu,
    match,
    handleShowMenu,
    showIsIcon,
  } = useContextSelector(MenuContext, (menu) => menu);

  const listMenu = useMemo(() => itensMenu, []);

  return {
    sizeMenu,
    listMenu,
    handleIsIcon,
    showMenu,
    match,
    handleShowMenu,
    showIsIcon,
  };
}
