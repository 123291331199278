import React from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { useInputPassword } from "./hooks/useInputPassword";
import { CiRead, CiUnread } from "react-icons/ci";

export function InputPassword({ ...props }) {
  const { showPassword, handleShowPassword } = useInputPassword();

  return (
    <TextField
      {...props}
      label="Senha"
      variant="outlined"
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleShowPassword}>
              {showPassword ? <CiUnread /> : <CiRead />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
