import React from "react";
import App from "./pages/App";
import { QueryClient, QueryClientProvider } from "react-query";
import { NotificationProvider } from "./context/NotificationContext";
import { createRoot } from "react-dom/client";
import { MenuContext } from "./context/MenuContext";

const queryclient = new QueryClient({});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryclient}>
      <NotificationProvider>
        <App />
      </NotificationProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
