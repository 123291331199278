import { useQuery } from "react-query";
import { axiosClient } from "../axiosClient";
import { useNotify } from "../../hooks/useNotify";

export function useApiDAshboard() {
  const { notify } = useNotify();
  const { data: dashBoardData, isLoading } = useQuery(
    "dashboard",
    async () => {
      return axiosClient
        .get(`api/checkin-list`)
        .then((response) => response.data);
    },
    {
      refetchInterval: 10000,
      onError: (error) => {
        notify("Erro ao buscar os dados do dashboard", "error");
      },
    }
  );

  return { dashBoardData, isLoading };
}
