import React from "react";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

import logo from "../../assets/images/logoControl.png";
import { useAuth } from "./hooks/useAuth";
import { Box, Stack, Typography } from "@mui/material";

import backgrounds from "../../assets/images/background-auth2.jpg";
import { InputPassword } from "../../components/inputPassword/InputPassword";

export default function Auth() {
  const { formik, isLoading } = useAuth();

  return (
    <Box component="section" sx={{ display: "flex", height: "100vh" }}>
      <Stack
        component="section"
        sx={{
          width: "50%",
          height: "100%",
          display: ["none", "none", "none", "flex"],

          backgroundImage: `url(${backgrounds})`,
          backgroundSize: "cover",
          backgroundPosition: "center",

          "&::before": {
            filter: "blur(5px)",
          },
        }}
        justifyContent="center"
      >
        <Stack alignItems="center">
          <img
            src={logo}
            alt="login"
            style={{ width: "60%", objectFit: "cover" }}
          />
        </Stack>
      </Stack>

      <Stack
        component="section"
        sx={{ flex: 1, px: [5, 5, 15], py: 10, gap: 3 }}
        justifyContent="center"
      >
        <Stack
          alignItems="center"
          mb={1}
          display={["flex", "flex", "flex", "none"]}
        >
          <img
            src={logo}
            alt="login"
            style={{ width: "60%", objectFit: "cover" }}
          />
        </Stack>
        <Stack component="header" gap={0.5}>
          <Typography variant="h4">
            Olá, <strong>seja bem-vindo!</strong>
          </Typography>
          <Typography variant="h5" sx={{ color: "#c3c3c3" }}>
            É um prazer recebê-lo em nosso sistema.
          </Typography>
        </Stack>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={1}>
            <TextField
              label="Email"
              name="email"
              type="email"
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <AlternateEmailIcon style={{ color: "#c3c3c3" }} />
                ),
              }}
              value={formik.values.email}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />

            <InputPassword
              label="Senha"
              name="password"
              type="password"
              fullWidth
              margin="dense"
              value={formik.values.password}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              helperText={formik.touched.password && formik.errors.password}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />

            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              sx={{ width: "100%", marginTop: 1 }}
              loading={isLoading}
            >
              Login
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
}
