import { useState } from "react";
import { useApiClients } from "../../../services/hooks/useApiClients";
import { ButtonsActions } from "../../../components/tablesBase/components/ButtonsActions";
import { DeleteOutline } from "@mui/icons-material";
import { BiEdit } from "react-icons/bi";
import { GrView } from "react-icons/gr";

export function useClients() {
  const [listIdClients, setListIdClients] = useState([]);
  const [updateClient, setUpdateClient] = useState({});

  const [openModalQrCode, setOpenModal] = useState(false);
  const [openModalUpdateAndCreateClient, setOpenModalUpdateAndCreateClient] =
    useState(false);
  const [openModalView, setOpenModalView] = useState(false);

  const { clients, isLoading, deleteClient } = useApiClients();

  const columns = [
    { header: "ID", accessorKey: "id" },
    { header: "Nome", accessorKey: "name_client" },
    { header: "Congregação", accessorKey: "name_church" },
  ];

  function handleSetListIdClients(ids) {
    setListIdClients(ids);
    setOpenModal(true);
  }

  function handleOpenModal() {
    if (openModalQrCode) {
      setOpenModal(false);
      setListIdClients([]);
      return;
    }
  }

  function handleDeleteClients(row) {
    const { original } = row;

    if (window.confirm(`Deseja deletar o cliente ${original.name_client}?`)) {
      deleteClient(original.id);
    }
  }

  function handleOpenModalCreateClient() {
    setUpdateClient(undefined);
    setOpenModalUpdateAndCreateClient(true);
  }

  function handleSetUpdateClient(row) {
    setUpdateClient(row.original);
    setOpenModalUpdateAndCreateClient(true);
  }

  function handleCloseModalUpdateClient() {
    setOpenModalUpdateAndCreateClient(false);
    setUpdateClient({});
  }

  function handleViewClient(row) {
    setUpdateClient(row.original);
    setOpenModalView(true);
  }

  function handleCloseModalView() {
    setOpenModalView(false);
    setUpdateClient(undefined);
  }

  function renderOtherActions(row) {
    const { original } = row;

    return (
      <>
        {original?.status !== "approved" && (
          <ButtonsActions
            title={"Remover participante"}
            onClick={() => handleDeleteClients(row)}
            icon={
              <DeleteOutline
                fontSize="inherit"
                color={original?.status === "approved" ? "disabled" : "error"}
              />
            }
            disabled={original?.status === "approved"}
          />
        )}

        <ButtonsActions
          title={"Editar participante"}
          onClick={() => handleSetUpdateClient(row)}
          icon={<BiEdit fontSize="inherit" />}
        />

        <ButtonsActions
          title={"Visualizar participante"}
          onClick={() => handleViewClient(row)}
          icon={<GrView fontSize="inherit" />}
        />
      </>
    );
  }

  return {
    clients,
    isLoading,
    columns,
    listIdClients,
    handleSetListIdClients,
    openModalQrCode,
    handleOpenModal,
    handleDeleteClients,
    renderOtherActions,
    handleCloseModalUpdateClient,

    openModalUpdateAndCreateClient,
    handleOpenModalCreateClient,

    updateClient,

    handleViewClient,
    handleCloseModalView,
    openModalView,
  };
}
