import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import AuthLayout from "../templates/auth";
import Main from "../templates/main";

import { SESSION_STORE_CONSTANTS } from "../constants/sessionStore";

function isAuthenticated() {
  const token = sessionStorage.getItem(SESSION_STORE_CONSTANTS.TOKEN);
  return token ? true : false;
}

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const isSigned = isAuthenticated();

  if (!isSigned && isPrivate) {
    return <Redirect to="/" />;
  }

  if (isSigned && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  const Layout = isPrivate && isSigned ? Main : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
RouteWrapper.prototype = {
  isPrivate: PropTypes.bool,
  compornent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
