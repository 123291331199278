import { Box, Drawer, IconButton, List, Stack, useTheme } from "@mui/material";
import SimpleBar from "simplebar-react";
import { useMenuDrawer } from "./hooks/useMenuDrawer";
import "simplebar-react/dist/simplebar.min.css";
import { ItemsMenu } from "../itemsMenu";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

export function MenuDrawer() {
  const theme = useTheme();
  const {
    sizeMenu,
    handleIsIcon,
    showMenu,
    match,
    handleShowMenu,
    showIsIcon,
    listMenu,
  } = useMenuDrawer();

  return (
    <Drawer
      variant={match ? "temporary" : "permanent"}
      open={showMenu}
      onClose={handleShowMenu}
      sx={{
        overflow: "hidden",
      }}
    >
      <SimpleBar
        forceVisible="y"
        autoHide={true}
        style={{
          height: `calc(100% - ${theme.spacing(13)})`,
          width: sizeMenu,
          marginTop: theme.spacing(8),
          overflowX: "hidden",
        }}
      >
        <List>
          {listMenu.map((item, index) => (
            <ItemsMenu
              key={index}
              icon={item.icon}
              label={item.title}
              path={item.link}
            />
          ))}
        </List>
      </SimpleBar>
      <Box
        sx={{
          position: `absolute`,
          bottom: 0,
          width: sizeMenu,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          px: 1,
          py: 0.7,
        }}
      >
        <Stack direction="row" justifyContent="flex-end" px={1} py={1}>
          <IconButton sx={{ fontSize: 16 }} onClick={handleIsIcon}>
            {showIsIcon ? (
              <ArrowForwardIos fontSize="inherit" />
            ) : (
              <ArrowBackIosNew fontSize="inherit" />
            )}
          </IconButton>
        </Stack>
      </Box>
    </Drawer>
  );
}
