import { useMutation, useQuery } from "react-query";
import { axiosClient } from "../axiosClient";
import { useNotification } from "../../context/NotificationContext";

export function useApiSales({ closeModalDownloadSale }) {
  const { showAlert } = useNotification();

  const {
    data: dataSale,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(
    "sales",
    async () => {
      const response = await axiosClient.get(`api/sales/0`);
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );

  const { mutate: deleteSales, isLoading: isLoadingDeleteSales } = useMutation(
    "deleteSales",
    ({ id }) => {
      return axiosClient.delete(`api/sales/${id}`);
    },
    {
      onSuccess: () => {
        refetch();
        showAlert("Venda deletada com sucesso!", "success");
      },
      onError: () => {
        showAlert("Erro ao deletar a venda!", "error");
      },
    }
  );

  const { mutate: resendEmail, isLoading: isLoadingResendEmail } = useMutation(
    "resendEmail",
    async (id_client) => {
      return axiosClient.get(`/api/sales/resendemail/${id_client}`);
    },
    {
      onSuccess: () => {
        refetch();
        showAlert("Email reenviado com sucesso!", "success");
      },
      onError: () => {
        showAlert("Erro ao reenviar o email!", "error");
      },
    }
  );

  const { mutate: downloadSale, isLoading: isLoadingDownloadSale } =
    useMutation(
      "downalodSale",
      async (dataUpdateSale) => {
        return axiosClient.put(
          `/api/sales/update/${dataUpdateSale.id}`,
          dataUpdateSale
        );
      },
      {
        onSuccess: () => {
          refetch();
          showAlert("Venda baixada com sucesso!", "success");
          closeModalDownloadSale && closeModalDownloadSale();
        },
        onError: () => {
          showAlert("Erro ao baixar a venda!", "error");
        },
      }
    );

  return {
    dataSale,
    isLoading: isLoading || isRefetching,
    deleteSales,
    isLoadingDeleteSales,
    downloadSale,
    isLoadingDownloadSale,

    resendEmail,
    isLoadingResendEmail,
  };
}
