import { useMediaQuery, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { createContext } from "use-context-selector";

export const MenuContext = createContext({});

export const MenuProvider = ({ children }) => {
  const theme = useTheme();
  const [showIsIcon, setShowIsIcon] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();

  const sizeMenu = useMemo(
    () => (showIsIcon ? theme.spacing(8) : theme.spacing(30)),
    [showIsIcon, theme]
  );
  const match = useMediaQuery(theme.breakpoints.down("lg"));

  const sizeHeader = theme.spacing(8);

  function handleIsIcon() {
    setShowIsIcon(!showIsIcon);
  }

  function handleShowMenu() {
    setShowMenu(!showMenu);
  }

  function handleControlMenuDrawer() {
    setShowMenu(!showMenu);
  }

  function handleNavigate(path) {
    history.push(path);
  }

  function useIsRouteMatch(route) {
    const location = useLocation();
    return location.pathname === route;
  }

  const handleLogout = () => {
    sessionStorage.clear();
    history.push("/");
  };
  return (
    <MenuContext.Provider
      value={{
        sizeMenu,
        match,
        showIsIcon,
        handleIsIcon,
        showMenu,
        handleShowMenu,
        handleControlMenuDrawer,
        handleNavigate,
        useIsRouteMatch,
        sizeHeader,
        handleLogout,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
