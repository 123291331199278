import { ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import { MenuContext } from "../../../context/MenuContext";
import { useContextSelector } from "use-context-selector";

export function ItemsMenu({ icon, label, path }) {
  const { showIsIcon, handleNavigate, useIsRouteMatch } = useContextSelector(
    MenuContext,
    (Menu) => Menu
  );

  return (
    <ListItem disablePadding>
      <ListItemButton
        disableGutters
        sx={{
          p: 2,
          height: 50,
          color: useIsRouteMatch(path) ? "primary.main" : "inherit",
        }}
        onClick={() => handleNavigate(path)}
      >
        <Stack
          sx={{
            mr: 2,
            ml: showIsIcon ? 1 : 0,
            fontSize: 20,
          }}
        >
          {icon}
        </Stack>
        <ListItemText
          primary={label}
          sx={{
            opacity: showIsIcon ? 0 : 1,
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
