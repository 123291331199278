import { GiChampions } from "react-icons/gi";
import { GoPeople } from "react-icons/go";
import { LiaUserSolid } from "react-icons/lia";
import { LuChurch, LuShoppingCart } from "react-icons/lu";
import { PiCashRegister } from "react-icons/pi";
import { RiDashboardLine } from "react-icons/ri";
import { TbReportAnalytics } from "react-icons/tb";

export const itensMenu = [
  {
    title: "Dashboard",
    link: "/dashboard",
    icon: <RiDashboardLine />,
  },
  {
    title: "Participantes",
    link: "/participantes",
    icon: <GoPeople />,
  },
  {
    title: "Vendas",
    link: "/vendas",
    icon: <LuShoppingCart />,
  },
  {
    title: "Congregações",
    link: "/igrejas",
    icon: <LuChurch />,
  },
  {
    title: "Financeiro",
    link: "/financeiro",
    icon: <PiCashRegister />,
  },
  {
    title: "Relatórios",
    link: "/relatorios",
    icon: <TbReportAnalytics />,
  },
  {
    title: "Sorteio",
    link: "/sorteio",
    icon: <GiChampions />,
  },
  {
    title: "Usuários",
    link: "/usuarios",
    icon: <LiaUserSolid />,
  },
];
