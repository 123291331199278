import { Button, Stack, TextField, Typography } from "@mui/material";

export function FormChurch({ formik, handleCloseModal, title }) {
  return (
    <Stack gap={3} p={3}>
      <Stack>
        <Typography variant="h5">{title}</Typography>
      </Stack>
      <Stack gap={3}>
        <input type="hidden" name="id" value={formik.values.id} />
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={2}>
            <TextField
              name="name_church"
              type="text"
              label="Nome"
              fullWidth
              size="normal"
              className="inputUser"
              value={formik.values.name_church}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                !!(formik.touched.name_church && formik.errors.name_church)
              }
              helperText={
                formik.touched.name_church && formik.errors.name_church
              }
            />

            <TextField
              name="responsible_church"
              type="text"
              label="Pastor responsavel"
              fullWidth
              size="normal"
              className="inputUser"
              value={formik.values.responsible_church}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                !!(
                  formik.touched.responsible_church &&
                  formik.errors.responsible_church
                )
              }
              helperText={
                formik.touched.responsible_church &&
                formik.errors.responsible_church
              }
            />

            <Stack direction="row" justifyContent="flex-end" gap={3}>
              <Button type="submit" variant="outlined">
                Salvar
              </Button>
              <Button
                color="error"
                onClick={handleCloseModal}
                variant="outlined"
              >
                Cancelar
              </Button>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
}
