import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Container, Content, Header, ViewResult, animateView } from "./style";
import { useHistory } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import logoEbom from "../../assets/images/ebom-logo.png";
import useApi from "../../services/api";
import { StateContext } from "../../context";

import background from "../../assets/images/background-sorteio.jpeg";
import logoEbom12 from "../../assets/images/logo-ebom-12.png";

import { motion } from "framer-motion";
import { useApiDraw } from "../../services/hooks/useApiDraw";
import Loading from "../../components/Loading";

export const PrizeDraw = () => {
  const history = useHistory();

  const { dataDraw, isLoading } = useApiDraw();

  const [toView, setToView] = useState(false);

  const [winner, setWinner] = useState({});
  const [error, setError] = useState("");
  const [listWinners, setListWinners] = useState([]);

  const backPage = () => {
    history.push("/dashboard");
  };

  const setDraw = useCallback(() => {
    if (!dataDraw || !dataDraw?.length) {
      setError("Não há participantes cadastrados para o sorteio!");
      setToView(true);
      return "";
    }
    setToView(false);
    while (true) {
      if (dataDraw?.length !== listWinners?.length + 1) {
        const draw = dataDraw[Math.floor(Math.random() * dataDraw?.length)];

        if (listWinners.indexOf(draw?.id) === -1) {
          setWinner(draw);
          setListWinners((winner) => [...winner, draw?.id]);
          setToView(true);
          return "";
        }
      } else {
        setError("Não há mais participantes disponível para o sorteio!");
        setToView(true);
        return "";
      }
    }
  }, [dataDraw, listWinners]);

  const reSetDraw = () => {
    setToView(false);
  };

  const animationVariants = {
    initial: {
      y: "-100vh", // Começa fora da tela, no topo
      opacity: 0.5, // Inicia com opacidade baixa
    },
    animate: {
      y: "0vh", // Move para o meio da tela (ajuste conforme necessário)
      opacity: 1, // Aumenta a opacidade para total
      transition: {
        y: {
          type: "spring", // Usa uma transição "spring" para um efeito de queda natural
          stiffness: 20, // Ajuste a rigidez para controlar a "elasticidade" da queda
          damping: 5, // Amortecimento da "spring"
        },
        opacity: {
          duration: 5.0, // Duração da transição de opacidade
          delay: 0.5, // Delay para iniciar a transição de opacidade após começar a mover
        },
      },
    },
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: 1500,
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "white",
        backgroundImage: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Stack justifyContent="space-between" height="100%">
        <Stack alignItems={"flex-end"} p={3}>
          <IconButton onClick={backPage}>
            <Close />
          </IconButton>
        </Stack>
        <Stack alignItems="center">
          {!toView && (
            <Button
              variant="contained"
              size="large"
              onClick={setDraw}
              sx={{ width: 250, height: 70 }}
              color="warning"
            >
              Sortear
            </Button>
          )}
          {toView && !error.length > 0 && (
            <motion.div
              variants={animationVariants}
              initial="initial"
              animate="animate"
            >
              <Stack alignItems={"center"} gap={5}>
                <Stack alignItems="center" gap={3}>
                  <Typography variant="h4" fontWeight={"bold"}>
                    Ganhador
                  </Typography>
                  <Typography variant="h1" fontWeight="bold">
                    {winner?.name_client.toUpperCase()}{" "}
                  </Typography>
                  <Typography variant="h3" fontWeight="bold" color="red">
                    {winner?.name_church?.toUpperCase()}
                  </Typography>
                </Stack>
                <Button
                  variant="contained"
                  color="warning"
                  size="large"
                  onClick={reSetDraw}
                  sx={{ width: 250, height: 70 }}
                >
                  Sortear novamente
                </Button>
              </Stack>
            </motion.div>
          )}
          {toView && error.length > 0 && (
            <Stack alignItems="center" gap={8}>
              <Typography variant="h2">{error}</Typography>
              <Button
                variant="contained"
                color="warning"
                size="large"
                onClick={reSetDraw}
                width={150}
                height={50}
                sx={{ width: 250, height: 70 }}
              >
                Sortear novamente
              </Button>
            </Stack>
          )}
        </Stack>
        <Stack alignItems="center">
          <img src={logoEbom12} alt="Logo Ebom" width={500} />
        </Stack>
      </Stack>
    </Box>
  );
};
