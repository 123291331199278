import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../../context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loading from "../../components/Loading";
import { Box, Stack } from "@mui/material";
import { MenuDrawer } from "./components/menuDrawer/MenuDrawer";

import { useContextSelector } from "use-context-selector";
import { MenuContext } from "../../context/MenuContext";
import { HeaderMain } from "./components/header/HeaderMain";

export default function Main({ children }) {
  const { state } = useContext(StateContext);
  const [showLoading, setShowLoading] = useState(false);

  const { sizeMenu, match, sizeHeader } = useContextSelector(
    MenuContext,
    (Menu) => Menu
  );

  const notify = (params) =>
    toast[params.type](params.text, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

  useEffect(() => {
    if (state.isError.status) {
      notify({ type: state.isError.type, text: state.isError.text });
    }
  }, [state.isError]);

  useEffect(() => {
    setShowLoading(state.loading);
  }, [state.loading]);

  return (
    <Box sx={{ display: "flex", flexDirection: "columns" }}>
      {showLoading && <Loading />}

      <HeaderMain />

      <Stack direction={"row"} mt={sizeHeader} width={"100vw"}>
        <MenuDrawer />
        <Box
          p={2}
          sx={{
            marginLeft: match ? 0 : sizeMenu,
            width: { match } ? "100%" : `calc(100% - ${sizeMenu})`,
          }}
        >
          {children}
        </Box>
      </Stack>

      <ToastContainer />
    </Box>
  );
}
