import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

export function ChartsBar({ data, dataKeyX, dataKeyY, dataKeyBar, colorBar }) {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart width={150} height={40} data={data}>
        <XAxis dataKey={dataKeyX?.key} name={dataKeyX?.name} />
        <YAxis dataKey={dataKeyY?.key} name={dataKeyY?.name} />
        <Tooltip />
        <Legend />
        {dataKeyBar.map((item) => (
          <Bar
            key={item.key}
            dataKey={item.key}
            fill={item.color}
            name={item.label}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
}
