import { DescriptionCharts } from "./style";

import Header from "../../components/header";

import Charts from "../../components/charts/chartsLine";
import ChartsDoughnut from "../../components/charts/chartsDoughnut";

import { MdDashboard, MdOutlineAnalytics } from "react-icons/md";
import { ListClientsForChurchs } from "./components/chartsDashboard/ListClientsForChurchs";
import { Divider, Paper, Stack, Typography } from "@mui/material";
import { useDashboard } from "./hooks/ useDashboard";
import { TableBase } from "../../components/tablesBase/TableBase";
import { CardsView } from "../../components/cardsView/CardsView";

export default function Dashboard() {
  const { client, columns, isLoading, reports, cards, presenceCountByChurch } =
    useDashboard();

  return (
    <Stack gap={4} pb={5}>
      <Header title="Dashboard" icon={<MdDashboard />} />

      <Stack direction={["column", "column", "column", "row"]} gap={2}>
        {cards.map((card) => {
          return <CardsView {...card} />;
        })}
      </Stack>

      <Stack direction="row" alignItems="center" gap={1}>
        <MdOutlineAnalytics fontSize={33} />
        <Typography variant="h5">Analises</Typography>
      </Stack>
      <Stack>
        <Paper elevation={2}>
          <Stack p={2} direction={["column", "column", "row"]} gap={2}>
            <Stack width={["100%", "100%", "70%"]}>
              <Typography variant={"h6"}>Total Presenças Diárias</Typography>

              <Charts
                dataCharts={reports?.presentsDay || []}
                isLoading={isLoading}
              />
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack flex={1} gap={2} alignItems="center">
              <Typography variant={"h6"}>Média de presenças</Typography>

              <ChartsDoughnut
                dataChart={reports}
                size={"60%"}
                isLoading={isLoading}
              />

              <DescriptionCharts></DescriptionCharts>
            </Stack>
          </Stack>
        </Paper>
      </Stack>

      <ListClientsForChurchs dataChurch={presenceCountByChurch} />

      <TableBase
        title="Lista de participantes presentes"
        columns={columns}
        data={client || []}
        isLoading={isLoading}
      />
    </Stack>
  );
}
