import { TrendingUp } from "@mui/icons-material";
import { Paper, Stack, Typography } from "@mui/material";

export function CardsView({ value, description, color, icon }) {
  return (
    <Stack width={"100%"}>
      <Paper>
        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          alignItems={"center"}
        >
          <Stack>
            <Typography variant="h4" sx={{ color: color }}>
              {value}
            </Typography>
            <Typography>{description}</Typography>
          </Stack>
          <Stack fontSize={35}>{icon}</Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          p={2}
          bgcolor={color}
          borderRadius="0 0  8px 8px"
          color="white"
          alignItems="center"
        >
          <Typography variant="h6">Totais</Typography>
          <TrendingUp />
        </Stack>
      </Paper>
    </Stack>
  );
}
