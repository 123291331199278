import { toast } from "react-toastify";

export function useNotify() {
  const notify = (message, type) => {
    toast[type](message, {
      position: "top-right",
      autoClose: 5000,
    });
  };

  return { notify };
}
