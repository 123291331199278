import { Paper, Stack, Typography, useTheme } from "@mui/material";

export function ListReports({ dataReport }) {
  const theme = useTheme();
  return (
    <Paper>
      <Stack p={2} direction="row" gap={3}>
        <Paper
          sx={{
            width: "100%",
            color: theme.palette.warning.light,
            borderColor: theme.palette.warning.light,
            border: 1,
            borderRadius: 2,
          }}
        >
          <Stack p={2}>
            <Typography variant="h6">Total em aberto</Typography>
            <Typography variant="h2" fontWeight="bold">
              {dataReport?.amountOpen}
            </Typography>
          </Stack>
        </Paper>
        <Paper sx={{ width: "100%" }}>
          <Stack
            p={2}
            sx={{
              color: theme.palette.success.light,
              borderColor: theme.palette.success.light,
              border: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Total pago</Typography>
            <Typography variant="h2" fontWeight="bold">
              {dataReport?.amountClose}
            </Typography>
          </Stack>
        </Paper>
        <Paper sx={{ width: "100%" }}>
          <Stack
            p={2}
            sx={{
              color: theme.palette.info.light,
              borderColor: theme.palette.info.light,
              border: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Total Isentos</Typography>
            <Typography variant="h2" fontWeight="bold">
              {dataReport?.amountExempt}
            </Typography>
          </Stack>
        </Paper>
        <Paper sx={{ width: "100%" }}>
          <Stack
            p={2}
            sx={{
              color: theme.palette.grey[800],
              borderColor: theme.palette.grey[800],
              border: 1,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6">Total de vendas</Typography>
            <Typography variant="h2" fontWeight="bold">
              {dataReport?.amountFull}
            </Typography>
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  );
}
