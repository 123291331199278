import { useMutation, useQuery } from "react-query";
import { axiosClient } from "../axiosClient";
import { useNotify } from "../../hooks/useNotify";

export function useApiClients({ onCloseModal } = {}) {
  const { notify } = useNotify();

  const {
    data: clients,
    isLoading,
    refetch,
  } = useQuery("clients", async () => {
    return axiosClient
      .get("api/clients")
      .then((response) => response.data.clients);
  });

  const { mutate: createClient, isLoading: isLoadingCreateClient } =
    useMutation(
      "createClient",
      async (data) => {
        return axiosClient.post("api/clients", data);
      },
      {
        onSuccess: () => {
          onCloseModal();
          refetch();
          notify("Participante criado com sucesso!", "success");
        },
        onError: () => {
          notify("Erro ao criar o participante!", "error");
        },
      }
    );

  const { mutate: updateClient, isLoading: isLoadingUpdateClient } =
    useMutation(
      "updateClient",
      async (data) => {
        return axiosClient.put(`api/clients/update/${data.id}`, data);
      },
      {
        onSuccess: () => {
          onCloseModal();
          refetch();
          notify("Participante atualizado com sucesso!", "success");
        },
        onError: () => {
          notify("Erro ao atualizar o participante!", "error");
        },
      }
    );

  const { mutate: deleteClient, isLoading: isLoadingDeleteClient } =
    useMutation(
      "deleteClient",
      async (id) => {
        return axiosClient.delete(`api/clients/${id}`);
      },
      {
        onSuccess: () => {
          refetch();
          notify("Participante deletado com sucesso!", "success");
        },
        onError: () => {
          notify("Erro ao deletar o participante!", "error");
        },
      }
    );

  return {
    clients,
    isLoading,
    deleteClient,
    isLoadingDeleteClient,
    createClient,
    isLoadingCreateClient,

    updateClient,
    isLoadingUpdateClient,
  };
}
