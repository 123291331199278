import { useQuery } from "react-query";
import { axiosClient } from "../axiosClient";
import { useNotify } from "../../hooks/useNotify";

export function useApiDraw() {
  const { notify } = useNotify();

  const { data: dataDraw, isLoading } = useQuery(
    "draw",
    async () => {
      return axiosClient
        .get("api/list-draw")
        .then((response) => response.data.clients);
    },
    {
      onError: () => {
        notify("Erro ao buscar os participantes!", "error");
      },
    }
  );

  return {
    dataDraw,
    isLoading,
  };
}
