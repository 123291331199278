import { useMutation, useQuery } from "react-query";
import { axiosClient } from "../axiosClient";
import { useNotify } from "../../hooks/useNotify";

export function useApiChruchs({ closeModal }) {
  const { notify } = useNotify();

  const {
    data: churchs,
    isLoading,
    refetch,
    isRefetching,
  } = useQuery(
    "churchs",
    async () => {
      return axiosClient
        .get("api/church")
        .then((response) => response.data.data);
    },
    {
      onError: () => {
        notify(
          "Ops tivemos um erro ao buscar a lista de congregações!",
          "error"
        );
      },
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: sendNewChurch, isLoading: isLoadingNewChurch } = useMutation(
    "createChurch",
    (payload) => {
      return axiosClient.post("api/church", payload);
    },
    {
      onSuccess: () => {
        notify("Congregação cadastrada com sucesso!", "success");
        refetch();
        closeModal && closeModal();
      },
      onError: () => {
        notify(
          "Ops tivemos um erro ao buscar a lista de congregações!",
          "error"
        );
      },
    }
  );

  const { mutate: updateChurch, isLoading: isLoadingUpdateChurch } =
    useMutation(
      "updateChurch",
      (payload) => {
        return axiosClient.put(`api/church/update/${payload.id}`, payload);
      },
      {
        onSuccess: () => {
          notify("Congregação atualizada com sucesso!", "success");
          refetch();
          closeModal && closeModal();
        },
        onError: () => {
          notify(
            "Ops tivemos um erro ao buscar a lista de congregações!",
            "error"
          );
        },
      }
    );

  const { mutate: deleteChurch, isLoading: isLoadingDeleteChurch } =
    useMutation(
      "deleteChurch",
      (id) => {
        return axiosClient.delete(`api/church/${id}`);
      },
      {
        onSuccess: () => {
          notify("Congregação deletada com sucesso!", "success");
          refetch();
        },
        onError: () => {
          notify(
            "Ops tivemos um erro ao buscar a lista de congregações!",
            "error"
          );
        },
      }
    );

  return {
    churchs,
    isLoading:
      isLoading ||
      isLoadingNewChurch ||
      isLoadingUpdateChurch ||
      isLoadingDeleteChurch ||
      isRefetching,
    sendNewChurch,

    updateChurch,

    deleteChurch,
  };
}
