import { useMemo, useState } from "react";
import { useApiSales } from "../../../services/hooks/useApiSales";
import { FormatedDate, formatCurrency } from "../../../utils/formation";
import { Chip } from "@mui/material";
import { typeOfSales } from "../../../constants/App";
import { ButtonsActions } from "../../../components/tablesBase/components/ButtonsActions";

import { FaCartArrowDown } from "react-icons/fa6";
import FormDownPay from "../../../components/FormDownPay";
import FormSales from "../Form";
import { GrSend } from "react-icons/gr";
import { DeleteOutline } from "@mui/icons-material";

export function useSales() {
  const [isOpenModalDownloadSale, setIsOpenModalDownloadSale] = useState(false);
  const [isOpenModalNewSale, setIsOpenModalNewSale] = useState(false);
  const [dataUpdateSale, setDataUpdateSale] = useState({});

  function handleCloseModalDonwload() {
    setIsOpenModalDownloadSale(false);
    setDataUpdateSale({});
  }

  function handleCloseModalNewSale() {
    setIsOpenModalNewSale(false);
  }

  const {
    dataSale,
    isLoading,
    deleteSales,
    isLoadingDeleteSales,
    downloadSale,
    isLoadingDownloadSale,
    resendEmail,
    isLoadingResendEmail,
  } = useApiSales({
    closeModalDownloadSale: handleCloseModalDonwload,
  });

  const statusList = useMemo(() => {
    return {
      pending: "Em aberto",
      approved: "Pago",
      canceled: "Cancelado",
    };
  }, []);

  const sales = useMemo(() => {
    return dataSale?.data?.map((sale) => {
      return {
        ...sale,
        value_sale:
          sale.paying_sale !== "yes" ? "-" : formatCurrency(sale.value_sale),
        chip_paying_sale:
          sale.paying_sale === "yes" ? (
            <Chip label="Pagante" />
          ) : (
            <Chip label="Isento" color="info" />
          ),
        paying_sale_name: sale.paying_sale === "yes" ? "Pagante" : "Isento",
        data_sale: FormatedDate(sale.created_at),
        status_name: !sale.status ? "Em aberto" : statusList[sale.status],
      };
    });
  }, [dataSale?.data]);

  const columnsTable = [
    {
      header: "Código",
      accessorKey: "id",
      size: 20,
    },
    {
      header: "Data da Venda",
      accessorKey: "data_sale",
    },
    {
      header: "Cliente",
      accessorKey: "client",
    },

    {
      header: "Valor",
      accessorKey: "value_sale",
    },
    {
      header: "Tipo",
      accessorKey: "paying_sale_name",
      Cell: ({ cell }) => {
        return cell.getValue() === "Pagante" ? (
          <Chip label="Pagante" />
        ) : (
          <Chip label="Isento" color="info" />
        );
      },
    },
    {
      header: "Forma de pagamento",
      accessorKey: "form_of_payment",
      Cell: ({ cell }) => typeOfSales?.[cell.getValue()] || "-",
    },
    {
      header: "Status",
      accessorKey: "status_name",
      Cell: ({ cell }) => {
        if (cell.getValue() === "Em aberto") {
          return <Chip label={cell.getValue()} color="warning" />;
        }
        if (cell.getValue() === "Pago") {
          return <Chip label={cell.getValue()} color="success" />;
        }
        if (cell.getValue() === "Cancelado") {
          return <Chip label={cell.getValue()} color="error" />;
        }
        return <Chip label="Em aberto" color="warning" />;
      },
    },
  ];

  function handleOpenModalDonwload(sale) {
    const { original } = sale;
    setIsOpenModalDownloadSale(!isOpenModalDownloadSale);
    setDataUpdateSale(original);
  }

  function renderOtherActions(row) {
    const { status } = row.original;
    return (
      <>
        {!status ||
          (status === "pending" && (
            <ButtonsActions
              title={"Baixar venda"}
              onClick={() => handleOpenModalDonwload(row)}
              icon={<FaCartArrowDown />}
            />
          ))}
        {status === "approved" && (
          <ButtonsActions
            title={"Reenviar email de confirmação"}
            onClick={() => handleResendEmail(row)}
            icon={<GrSend />}
            disabled={isLoadingResendEmail}
          />
        )}

        <ButtonsActions
          title={"Remover"}
          onClick={() => handleRemoveSale(row)}
          icon={
            <DeleteOutline
              fontSize="inherit"
              color={status === "approved" ? "disabled" : "error"}
            />
          }
          disabled={status === "approved"}
        />
      </>
    );
  }

  function handleRemoveSale(sale) {
    if (window.confirm("Deseja realmente excluir esta venda?")) {
      const { id_client } = sale?.original || {};

      deleteSales(id_client);
    }
  }

  function handleOpenModalNewSale() {
    setIsOpenModalNewSale(!isOpenModalNewSale);
  }

  function handleResendEmail(sale) {
    const { id_client } = sale?.original || {};
    id_client && resendEmail(id_client);
  }

  const listModais = useMemo(
    () => [
      {
        title: "Baixar venda",
        open: isOpenModalDownloadSale,
        onClose: handleCloseModalDonwload,
        Component: (
          <FormDownPay
            data={dataUpdateSale}
            onClose={handleCloseModalDonwload}
            handleSubmit={downloadSale}
            isLoading={isLoadingDownloadSale}
          />
        ),
      },

      {
        title: "Adicionar venda",
        open: isOpenModalNewSale,
        onClose: handleCloseModalNewSale,
        Component: (
          <FormSales
            // idUpdateSale={idUpdateSale}
            setCloseModal={handleCloseModalNewSale}
          />
        ),
      },
    ],
    [isOpenModalDownloadSale, isOpenModalNewSale]
  );

  return {
    dataSale: sales,
    dataReport: dataSale?.statusList,
    isLoading: isLoading || isLoadingDeleteSales,
    columnsTable,
    handleRemoveSale,
    renderOtherActions,
    isOpenModalDownloadSale,
    handleCloseModalDonwload,
    handleOpenModalNewSale,

    listModais,
  };
}
