import { format, isValid, parseISO } from "date-fns";

export function formatCurrency(value) {
  if (value === undefined) {
    return "R$ 0,00";
  }
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}

export function FormatedDate(date) {
  const parsedDate = parseISO(date); // Converte a string ISO para um objeto Date

  if (date && isValid(parsedDate)) {
    // Verifica se a data é válida após a conversão
    return format(parsedDate, "dd/MM/yyyy"); // Usa o objeto Date convertido
  }

  return "-";
}

export function formatDateTimeBR(dateTime) {
  const parsedDateTime = parseISO(dateTime); // Converte a string ISO para um objeto Date

  if (dateTime && isValid(parsedDateTime)) {
    // Verifica se a data e horário são válidos após a conversão
    return format(parsedDateTime, "dd/MM/yyyy HH:mm"); // Formata para o padrão brasileiro
  }

  return "-";
}
