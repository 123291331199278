import { useMemo } from "react";
import { useApiDAshboard } from "../../../services/hooks/useApiDashboard";
import { formatDateTimeBR } from "../../../utils/formation";
import { useTheme } from "@mui/material";
import { LuUser } from "react-icons/lu";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdOutlineDoNotDisturbAlt } from "react-icons/md";

export function useDashboard() {
  const theme = useTheme();
  const { dashBoardData, isLoading } = useApiDAshboard();

  const { client, reports } = dashBoardData || {};

  const { presenceCountByChurch } = reports || {};

  const columns = useMemo(() => {
    return [
      { header: "Nome", accessorKey: "name_client" },
      { header: "Igreja", accessorKey: "name_church" },
      {
        header: "Checkin",
        accessorKey: "data_checkin",
        Cell: ({ row }) => formatDateTimeBR(row.original.data_checkin),
      },
    ];
  }, []);

  const cards = useMemo(() => {
    return [
      {
        value: reports?.totalClient || 0,
        description: "Usuários Cadastrados",
        color: theme.palette.primary.main,
        icon: <LuUser />,
      },
      {
        value: reports?.present || 0,
        description: "Usuários Presentes",
        color: theme.palette.success.main,
        icon: <IoMdCheckboxOutline />,
      },
      {
        value: reports?.totalAbserts || 0,
        description: "Usuários Ausentes",
        color: theme.palette.error.main,
        icon: <MdOutlineDoNotDisturbAlt />,
      },
    ];
  }, [reports, theme]);

  return {
    dashBoardData,
    isLoading,
    columns,
    client,
    reports,
    presenceCountByChurch,
    cards,
  };
}
