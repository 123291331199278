import { AppBar, IconButton, Stack, Tooltip, useTheme } from "@mui/material";
import { useContextSelector } from "use-context-selector";
import { MenuContext } from "../../../../context/MenuContext";

import logoDefault from "../../../../assets/images/logoControl.png";
import logoIcon from "../../../../assets/images/logo-rbg.png";
import { Menu } from "@mui/icons-material";
import { CiLogout } from "react-icons/ci";

export function HeaderMain() {
  const {
    sizeHeader,
    match,
    showIsIcon,
    sizeMenu,
    handleShowMenu,
    handleLogout,
  } = useContextSelector(MenuContext, (Menu) => Menu);
  const theme = useTheme();
  return (
    <AppBar position="fixed" sx={{ zIndex: 1300, height: sizeHeader }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack
          width={!match ? sizeMenu : theme.spacing(8)}
          alignItems="center"
          sx={{ borderRight: !match ? "1px solid #c3c3c3" : "", py: 1 }}
          height="100%"
        >
          <img
            src={match || showIsIcon ? logoIcon : logoDefault}
            alt="Logo tipo"
            style={{
              height: 50,
              width: "auto",
              cursor: "pointer",
            }}
          />
        </Stack>
        <Stack
          flex={1}
          direction="row"
          justifyContent={!match ? "flex-end" : "space-between"}
          alignItems="center"
          px={2}
        >
          <Stack display={match ? "flex" : "none"}>
            <IconButton onClick={handleShowMenu} sx={{ color: "white" }}>
              <Menu />
            </IconButton>
          </Stack>
          <Stack>
            <Tooltip title="Sair">
              <IconButton sx={{ color: "white" }} onClick={handleLogout}>
                <CiLogout />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </AppBar>
  );
}
